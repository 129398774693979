












































import { api } from "@/api/api";
import { ApiGetTeachingMaterialCategoryDto } from "@/api/generated/Api";
import TeachingMaterialCategoriesModal from "@/components/administration/teachingmaterials/TeachingMaterialCategoriesModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TeachingMaterialCatogoriesPage",
  components: { BaseTooltipIconButton, BaseTableFiltered, BaseModal, TeachingMaterialCategoriesModal },
  setup() {
    const teachingMaterialCategories = ref<ApiGetTeachingMaterialCategoryDto[]>();
    const search = ref<string>("");
    const modalData = ref<ModalBaseData>({
      modalHeadline: "",
      modalType: ModalType.Unspecified,
      showModal: false,
    });

    const loadData = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = await api.teachingmaterialcategory.getTeachingMaterialCategoryAsync();
        teachingMaterialCategories.value = response.data;
      });
    };

    const openNewTeachingmaterialCategory = useOpenModal(ModalType.Add, "læremiddel-kategori", modalData);
    const openDisplayTeachingmaterialCategory = useOpenModal(ModalType.Display, "læremiddel-kategori", modalData);
    const openEditTeachingmaterialCategory = useOpenModal(ModalType.Edit, "læremiddel-kategori", modalData);

    const closeModal = () => {
      modalData.value.showModal = false;
      loadData();
    };

    onMounted(async () => {
      loadData();
    });
    return {
      teachingMaterialCategories,
      search,
      headers,
      modalData,
      openNewTeachingmaterialCategory,
      openDisplayTeachingmaterialCategory,
      openEditTeachingmaterialCategory,
      closeModal,
    };
  },
});

const headers = [
  { text: "Navn", value: "name" },
  {
    text: "Beskrivelse",
    value: "description",
  },
  { text: "Handlinger", value: "actions" },
];
